<template>
<b-container fluid>
	<bo-page-title />
	<section id="articleList">
    <HeroSection :modulePage="modulePage" v-if="isList && moduleRole('hero')" />
		
		<b-tabs v-if="isList">
			<b-tab title="All Posts" id="article" active>

				<b-card no-body>
					<b-card-header>
						<div class="d-flex align-items-center">
							<h5 class="card-title mb-0">CSR Posts</h5>
							<div v-if="Object.keys(dtCounter).length" class="ml-3">
								<b-button class="btn-rounded" size="sm" :variant="status=='all'?'info':'outline-info'" :to="{name:$route.name, query:Object.assign({}, this.$route.query||{}, {page:1, status:'all'})}">
									All <span>({{ dtCounter.all||0 }})</span>
								</b-button>
								<b-button class="btn-rounded ml-1" size="sm" :variant="status=='D'?'info':'outline-info'" :to="{name:$route.name, query:Object.assign({}, this.$route.query||{}, {page:1, status:'D'})}">
									Draft <span>({{ dtCounter.draft||0 }})</span>
								</b-button>
								<b-button class="btn-rounded ml-1" size="sm" :variant="status=='P'?'info':'outline-info'" :to="{name:$route.name, query:Object.assign({}, this.$route.query||{}, {page:1, status:'P'})}">
									Published <span>({{ dtCounter.published||0 }})</span>
								</b-button>
								<b-button class="btn-rounded ml-1" size="sm" :variant="status=='N'?'info':'outline-info'" :to="{name:$route.name, query:Object.assign({}, this.$route.query||{}, {page:1, status:'N'})}">
									Unpublished <span>({{ dtCounter.unpublished||0 }})</span>
								</b-button>
							</div>
						</div>
					</b-card-header>

					<b-card-header class="border-top">
						<b-row>
							<b-col md="8">
								<b-form-row>
									<b-col md="auto">
										<b-button-group>
											<b-button
												@click="viewMode = 'list'"
												v-b-tooltip.hover title="List View"
												:variant="viewMode == 'list' ? 'info' : ''"
											>
												<i class="fas fa-list"></i>
											</b-button>
											<b-button
												@click="viewMode = 'grid'"
												v-b-tooltip.hover title="Grid View"
												:variant="viewMode == 'grid' ? 'info' : ''"
											>
												<i class="fas fa-th"></i>
											</b-button>
										</b-button-group>
									</b-col>
								</b-form-row>
							</b-col>
							<b-col md="4">
								<b-form @submit.prevent="doFilter()" class="d-flex">
									<b-input-group>
									<b-form-input v-model="filter.search" v-on:keyup.13="$emit('search')" placeholder="Type keyword then enter..."></b-form-input>
									<b-input-group-append>
										<b-button @click="doFilter()" variant="success"><i class="fas fa-search"></i></b-button>
									</b-input-group-append>
									</b-input-group>
									<b-button
										class="ml-1 d-inline-flex align-items-center"
										variant="outline-success"
										@click="doReset()"
									>Reset</b-button>
								</b-form>
							</b-col>
						</b-row>
					</b-card-header>
					
					<template v-if="viewMode == 'list'">
						<ListTable v-bind="passListComp" />
					</template>
					<template v-else>
						<ListCard v-bind="passListComp" />
					</template>
					<b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>10">        
						<b-pagination
						class="mb-0"
						v-model="pageNo"
						:per-page="data.per_page"
						:total-rows="data.total"
						/>
					</b-card-footer>
				</b-card>
			</b-tab>
			<b-tab title="SEO Settings" v-if="moduleRole('seo')">
				<SeoSection />
			</b-tab>
		</b-tabs>

		<template v-else>
      <Form :row.sync="row" v-bind="passFormComp" />
    </template>
		
	</section>
</b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'

import Form from './Form.vue'
import ListTable from './ListTable.vue'
import ListCard from './ListCard.vue'
import SeoSection from '@/components/SEOSection'
import HeroSection from '@/components/HeroSection'

export default {
	name: 'BoCSR',
	extends: GlobalVue,
	components: {
		SeoSection, HeroSection,
		ListTable, ListCard, Form
	},
	data() {
		return {
			openContent:false,
			idKey:'ac_id',
			statusKey:'ac_status',
			linkDetailID: '',
			linkDetailEN: '',
			linkDetailJP: '',
			mrCategory:[],
			heroSetting:{},
			seoPageSetting:{},
			dtCounter:{},
			viewMode:'list'
		}
	},
	computed: {
		passListComp(){
			return {
				data:this.data,
				dataList:this.dataList,
				mrCategory:this.mrCategory,
				filter:this.filter,
				perPage:this.perPage,
				idKey:this.idKey,
				pageTitle:this.pageTitle,
			}
		},
		passFormComp(){
			return Object.assign(this.passToSubComp, {
				mrCategory:this.mrCategory,
				linkDetailEN: this.linkDetailEN,
				linkDetailID: this.linkDetailID,
				linkDetailJP: this.linkDetailJP,
			})
		},
		status(){
			return this.$route.query.status||"all"
		}
	},
	methods:{
		getMainData(){
			if(!(this.$route.query||{}).viewMode && this.isList){
				this.$router.push({name:this.$route.name, query:Object.assign({}, this.$route.query||{}, {page:this.$route.query.page||1, viewMode:'list'})}).catch(()=>{})
			}else{
				if(this.isList){
					this.viewMode = (this.$route.query||{}).viewMode
				}
				this.apiGet()
			}
		},
		doView(item){
			this.row = JSON.parse(JSON.stringify(item))
			this.$bvModal.show('ModalDetail')
		},
	},
	mounted(){
    this.getMainData()
  },
	watch: {
		$route(){
      this.getMainData()
    },
		viewMode(viewMode){
			this.$router.push({name:this.$route.name, query:Object.assign({}, this.$route.query||{}, {page:this.$route.query.page||1, viewMode:viewMode})}).catch(()=>{})
		},
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    },
	}
}
</script>